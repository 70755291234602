'use client';

import { toast } from 'components/ui/sonner';
import { useEffect } from 'react';

const ERROR_MESSAGES = {
  'referral-code-not-found': 'Referral code not found',
};

export default function ErrorToast({
  error,
}: {
  error?: 'referral-code-not-found';
}) {
  useEffect(() => {
    if (error) {
      toast.error(ERROR_MESSAGES[error]);
    }
  }, [error]);

  return null;
}
