import { Heart, HeartIcon, HeartOff } from 'lucide-react';

import { Button } from './ui/button';
import Coin from './coin';
import Image from 'next/image';
import Typography from './ui/typography';
import { cn } from 'utils/shadcn';
import dynamic from 'next/dynamic';
import { toast } from 'components/ui/sonner';
import { useOptimistic } from 'react';

const BoxCardVolatility = dynamic(() => import('./box-card-volatility'), {
  ssr: true,
});

export default function BoxCard({
  id,
  image,
  price,
  name,
  isFavorite,
  priority = false,
  toggleFavorite,
  color,
  cov,
}: {
  id: string;
  image: string;
  price: string;
  name: string;
  isFavorite: boolean;
  color: string;
  priority?: boolean;
  toggleFavorite: (formData: FormData) => void;
  cov: number;
}) {
  const [optimisticFavorite, toggleOptimisticFavorite] = useOptimistic(
    isFavorite,
    (state, newFavorite: boolean) => newFavorite,
  );

  return (
    <div className="relative aspect-[170/290] w-full">
      <div className="absolute z-10 aspect-square w-full">
        <Image
          src={image}
          alt={name}
          className="rounded-2xl object-cover"
          fill
          sizes="(max-width: 640px) 50vw, (max-width: 768px) 33vw, (max-width: 1024px) 25vw, 20vw"
          priority={priority}
        />
      </div>
      <div
        className="absolute bottom-0 w-full overflow-hidden rounded-lg"
        style={{
          height: `${22000 / 290}%`,
          background: 'rgba(21, 22, 26, 0.22)',
        }}
      >
        <div className="absolute bottom-0 flex w-full flex-col items-center justify-center gap-3 px-2 pb-0 pt-4">
          <Typography variant="p" affects="small" className="text-center">
            {name}
          </Typography>

          <Divider />

          <span className="grid grid-cols-[1fr_auto_1fr] items-center gap-1">
            <div className="relative w-full">
              <BoxCardVolatility cov={cov} />
            </div>
            <Button variant="frost" size="sm">
              <Coin className="mr-1 size-4" /> {price}
            </Button>
            <form
              action={(formData) => {
                toggleOptimisticFavorite(!optimisticFavorite);
                toggleFavorite(formData);
                if (optimisticFavorite) {
                  toast.success(
                    <span>
                      <strong>{name}</strong> removed from favorites
                    </span>,
                    {
                      icon: (
                        <HeartOff className="size-4 fill-transparent text-muted-foreground" />
                      ),
                    },
                  );
                } else {
                  toast.success(
                    <span>
                      <strong>{name}</strong> added to favorites
                    </span>,
                    {
                      icon: (
                        <Heart className="size-4 fill-destructive text-destructive" />
                      ),
                    },
                  );
                }
              }}
            >
              <Button
                variant="ghost"
                type="submit"
                size="icon"
                className="hover:bg-transparent"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <HeartIcon
                  className={cn('size-4 transition-colors', {
                    'fill-destructive text-destructive': optimisticFavorite,
                    'fill-transparent text-muted-foreground':
                      !optimisticFavorite,
                  })}
                />
              </Button>
              <input type="hidden" name="box_id" value={id} />
            </form>
          </span>
        </div>
        <div
          className="absolute -z-10 rounded-[500%] blur-3xl scale-150"
          style={{
            background: color,
            width: `${25200 / 170}%`,
            height: `${14700 / 290}%`,
            top: `${31400 / 290}%`,
            left: `${-4100 / 170}%`,
          }}
        ></div>
      </div>
    </div>
  );
}

function Divider(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={72}
      height={16}
      fill="none"
      {...props}
    >
      <path stroke="url(#left)" strokeLinecap="round" d="M1 8h20" />
      <path stroke="url(#right)" strokeLinecap="round" d="M71 8H51" />
      <path
        fill="#B8A171"
        fillRule="evenodd"
        d="M30.414 9.486 29 8.07l1.414-1.414 4.172-4.171L36 1.07l1.414 1.415 4.172 4.171L43 8.071l-1.414 1.415-4.172 4.171L36 15.071l-1.414-1.414-4.172-4.171ZM43 8.07l2-2 2 2-2 2-2-2Zm-14 0-2 2-2-2 2-2 2 2Zm7 4.172 4.172-4.172L36 3.9 31.828 8.07 36 12.243Zm0-6.172-2 2 2 2 2-2-2-2Z"
        clipRule="evenodd"
      />
      <defs>
        <linearGradient
          id="left"
          x1={1}
          x2={21}
          y1={8}
          y2={8}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#504521" stopOpacity={0} />
          <stop offset={0.635} stopColor="#B6A66F" stopOpacity={0.965} />
          <stop offset={1} stopColor="#E4D8AC" />
        </linearGradient>
        <linearGradient
          id="right"
          x1={51}
          x2={71}
          y1={8}
          y2={8}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E4D8AC" />
          <stop offset={0.365} stopColor="#B6A66F" stopOpacity={0.965} />
          <stop offset={1} stopColor="#504521" stopOpacity={0} />
        </linearGradient>
      </defs>
    </svg>
  );
}
