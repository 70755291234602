'use client';

import * as React from 'react';

import CrossIcon from 'icons/cross';
import Divider from './divider';
import Typography from './typography';
import { cn } from 'utils/shadcn';
import dynamic from 'next/dynamic';

const Close = dynamic(() =>
  import('@radix-ui/react-dialog').then((mod) => mod.Close),
);
const Content = dynamic(() =>
  import('@radix-ui/react-dialog').then((mod) => mod.Content),
);
const Description = dynamic(() =>
  import('@radix-ui/react-dialog').then((mod) => mod.Description),
);
const Overlay = dynamic(() =>
  import('@radix-ui/react-dialog').then((mod) => mod.Overlay),
);
const Portal = dynamic(() =>
  import('@radix-ui/react-dialog').then((mod) => mod.Portal),
);
const Root = dynamic(() =>
  import('@radix-ui/react-dialog').then((mod) => mod.Root),
);
const Title = dynamic(() =>
  import('@radix-ui/react-dialog').then((mod) => mod.Title),
);
const Trigger = dynamic(() =>
  import('@radix-ui/react-dialog').then((mod) => mod.Trigger),
);

const Dialog = Root;

const DialogTrigger = Trigger;

const DialogPortal = Portal;

const DialogClose = Close;

const DialogOverlay = React.forwardRef<
  React.ElementRef<typeof Overlay>,
  React.ComponentPropsWithoutRef<typeof Overlay>
>(({ className, ...props }, ref) => (
  <Overlay
    ref={ref as React.Ref<HTMLDivElement>}
    className={cn(
      'fixed inset-0 z-50 grid place-items-center overflow-y-auto bg-black/80 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0',
      className,
    )}
    {...props}
  />
));
DialogOverlay.displayName = Overlay.displayName;

const DialogContent = React.forwardRef<
  React.ElementRef<typeof Content>,
  React.ComponentPropsWithoutRef<typeof Content>
>(({ className, children, ...props }, ref) => (
  <DialogPortal>
    <DialogOverlay>
      <Content
        ref={ref as React.Ref<HTMLDivElement>}
        className={cn(
          'z-50 grid w-full max-w-lg gap-4 border bg-background p-6 shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 sm:rounded-lg md:my-8',
          className,
        )}
        {...props}
      >
        {children}
      </Content>
      <Close className="fixed right-4 top-4 z-50 rounded-sm opacity-70 transition-opacity hover:opacity-100 focus:outline-none disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground">
        <CrossIcon className="text-gold" />
        <span className="sr-only">Close</span>
      </Close>
    </DialogOverlay>
  </DialogPortal>
));
DialogContent.displayName = Content.displayName;

const DialogHeader = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div className="space-y-2">
    <div
      className={cn(
        'flex flex-col space-y-1.5 text-center sm:text-left',
        className,
      )}
      {...props}
    ></div>
    <Divider />
  </div>
);
DialogHeader.displayName = 'DialogHeader';

const DialogFooter = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      'flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2',
      className,
    )}
    {...props}
  />
);
DialogFooter.displayName = 'DialogFooter';

const DialogTitle = React.forwardRef<
  React.ElementRef<typeof Title>,
  React.ComponentPropsWithoutRef<typeof Title>
>(({ children, ...props }, ref) => (
  <Title ref={ref as React.Ref<HTMLDivElement>} {...props} asChild>
    <Typography variant="h1" className="text-gold-shimmer text-center">
      {children}
    </Typography>
  </Title>
));
DialogTitle.displayName = Title.displayName;

const DialogDescription = React.forwardRef<
  React.ElementRef<typeof Description>,
  React.ComponentPropsWithoutRef<typeof Description>
>(({ className, ...props }, ref) => (
  <Description
    ref={ref as React.Ref<HTMLDivElement>}
    className={cn('text-sm text-muted-foreground', className)}
    {...props}
  />
));
DialogDescription.displayName = Description.displayName;

export {
  Dialog,
  DialogPortal,
  DialogOverlay,
  DialogTrigger,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogDescription,
};
