import Image, { type ImageProps } from 'next/image';

import coin from 'public/coin.png';

export default function Coin({
  sizing = 'base',
  ...props
}: Partial<ImageProps> & { sizing?: 'base' | 'sm' | 'md' }) {
  let size;
  switch (sizing) {
    case 'base':
      size = 18;
      break;
    case 'sm':
      size = 14;
      break;
  }

  return <Image {...props} src={coin} alt="Coin" width={size} height={size} />;
}
