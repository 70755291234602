'use client';

import { ToggleGroup, ToggleGroupItem } from 'components/ui/toggle-group';
import { useCallback, useEffect, useMemo, useState } from 'react';

import BoxGrid from 'components/box-grid';
import { Button } from 'components/ui/button';
import { Input } from 'components/ui/input';
import { MagnifyingGlassIcon } from '@radix-ui/react-icons';
import Typography from 'components/ui/typography';
import dynamic from 'next/dynamic';

const TagsSelect = dynamic(() => import('components/tags-select'), {
  loading: () => (
    <Button variant="outline" disabled>
      All Tags
    </Button>
  ),
  ssr: false,
});

type Box = {
  id: string;
  image: string;
  name: string;
  price: string;
  pathname: string;
  splash: string;
  tags: string[];
  items: { name: string }[];
  isFavorite: boolean;
  color: string;
  cov: number;
};

export default function Home({
  boxes,
  category,
  setCategory,
  toggleFavorite,
}: {
  boxes: Box[];
  category: string;
  setCategory: (formData: FormData) => void;
  toggleFavorite: (formData: FormData) => void;
}) {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedTag, setSelectedTag] = useState<string>('all');
  const [filteredBoxes, setFilteredBoxes] = useState<Box[]>(boxes);
  const favoriteBoxes = useMemo(
    () => filteredBoxes.filter((box) => box.isFavorite),
    [filteredBoxes],
  );

  const tags = useMemo(
    () => [...new Set(boxes.flatMap((box) => box.tags))],
    [boxes],
  );

  const debouncedSearch = useCallback(
    async (term: string) => {
      const Fuse = (await import('fuse.js')).default;
      const fuse = new Fuse(boxes, {
        keys: ['name', 'items.name'],
        threshold: 0.3,
        isCaseSensitive: true,
      });

      let filteredResults = boxes;

      if (term) {
        const searchResults = fuse.search(term).map((result) => result.item);
        filteredResults = searchResults;
      }

      return filteredResults;
    },
    [boxes],
  );

  const applyFilters = (searchResults: Box[], tag: string) => {
    if (tag !== 'all') {
      return searchResults.filter((box) => box.tags.includes(tag));
    }
    return searchResults;
  };

  useEffect(() => {
    setFilteredBoxes(boxes);
    setSearchTerm('');
    setSelectedTag('all');
  }, [boxes]);

  useEffect(() => {
    const updateResults = async () => {
      const searchResults = await debouncedSearch(searchTerm);
      setFilteredBoxes(applyFilters(searchResults ?? [], selectedTag));
    };
    void updateResults();
  }, [searchTerm, selectedTag, debouncedSearch]);

  return (
    <div>
      <div className="mb-8 grid grid-cols-[1fr_auto_auto] items-center gap-2">
        <form
          action={setCategory}
          className="col-span-3 place-self-start md:col-span-1"
        >
          <ToggleGroup type="single" defaultValue={category}>
            <ToggleGroupItem value="mtg" asChild>
              <button type="submit" name="category" value="mtg">
                MTG
              </button>
            </ToggleGroupItem>
            <ToggleGroupItem value="pokemon" asChild>
              <button type="submit" name="category" value="pokemon">
                Pokemon
              </button>
            </ToggleGroupItem>
          </ToggleGroup>
        </form>

        <TagsSelect
          tags={tags}
          selectedTag={selectedTag}
          setSelectedTag={setSelectedTag}
        />

        <Input
          type="search"
          placeholder="Search"
          name="q"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          iconLeft={<MagnifyingGlassIcon className="size-5" />}
        />
      </div>

      <div className="flex flex-col gap-12">
        {favoriteBoxes.length > 0 && (
          <div>
            <Typography
              variant="h2"
              affects="2xl"
              className="text-gold-shimmer font-black"
            >
              Favorite Boxes
            </Typography>
            <BoxGrid boxes={favoriteBoxes} toggleFavorite={toggleFavorite} />
          </div>
        )}

        <div>
          <Typography
            variant="h2"
            affects="2xl"
            className="text-gold-shimmer font-black"
          >
            All Boxes
          </Typography>
          <BoxGrid boxes={filteredBoxes} toggleFavorite={toggleFavorite} />
          {filteredBoxes.length === 0 && (
            <div className="text-sm text-gray-500">No boxes found</div>
          )}
        </div>
      </div>
    </div>
  );
}
