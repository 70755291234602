'use client';

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from 'components/ui/dialog';
import {
  clearAffiliateCookie,
  redeemAffiliateCode,
} from 'actions/affiliate-codes';
import { useCallback, useState } from 'react';

import { Button } from 'components/ui/button';
import { ERROR_CODES } from 'lib/errors';
import { toast } from 'components/ui/sonner';
import { useAction } from 'next-safe-action/hooks';
import { useAuthDialog } from 'components/auth/auth-dialog-context';

export default function ReferralLinkDialog({
  data,
}: {
  data: {
    code: string;
    depositor_bonus_percentage: number;
  };
}) {
  const [open, setOpen] = useState(true);

  const closeDialog = useCallback(async () => {
    setOpen(false);
    await clearAffiliateCookie();
  }, []);

  return (
    <Dialog open={open} onOpenChange={closeDialog}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Use an Affiliate Code</DialogTitle>
        </DialogHeader>
        <DialogDescription>
          You are invited to use affiliate code <strong>{data.code}</strong>.
          This code will give you a{' '}
          <strong>{data.depositor_bonus_percentage}%</strong> deposit bonus on
          all deposits. You won&apos;t be able to change your code for the next
          7 days.
        </DialogDescription>

        <div className="flex items-center justify-center gap-2">
          <Button onClick={closeDialog}>Cancel</Button>
          <RedeemAffiliateCodeForm code={data.code} onSubmit={closeDialog} />
        </div>
      </DialogContent>
    </Dialog>
  );
}

function RedeemAffiliateCodeForm({
  code,
  onSubmit,
}: {
  code: string;
  onSubmit?: () => void;
}) {
  const { executeAsync, isExecuting } = useAction(redeemAffiliateCode);
  const { showAuthDialog } = useAuthDialog();

  const handleSubmit = useCallback(async () => {
    const result = await executeAsync({ code });

    if (result?.serverError) {
      console.log(JSON.stringify(result.serverError, null, 2));
      if (result.serverError.code === ERROR_CODES.UNAUTHORIZED) {
        showAuthDialog();
      } else {
        toast.error(result.serverError.message);
        onSubmit?.();
      }
    }

    if (result?.data) {
      toast.success(
        `🎉 Code ${code} redeemed! ${result.data[0]?.depositor_bonus_percentage}% extra coins will be added to your balance for each purchase for the next 7 days.`,
      );
      onSubmit?.();
    }
  }, [executeAsync, code, onSubmit, showAuthDialog]);

  return (
    <Button
      variant="action"
      isProcessing={isExecuting}
      onClick={handleSubmit}
      type="button"
    >
      Redeem
    </Button>
  );
}
