'use client';

import dynamic from 'next/dynamic';
import type { toast } from 'sonner';
import { useTheme } from 'next-themes';

const Sonner = dynamic(() => import('sonner').then((mod) => mod.Toaster), {
  ssr: false,
});

type ToasterProps = React.ComponentProps<typeof Sonner>;

const Toaster = ({ ...props }: ToasterProps) => {
  const { theme = 'dark' } = useTheme();

  return (
    <Sonner
      theme={theme as ToasterProps['theme']}
      className="toaster group"
      toastOptions={{
        classNames: {
          toast:
            'group toast group-[.toaster]:bg-background group-[.toaster]:text-foreground group-[.toaster]:border-border group-[.toaster]:shadow-lg',
          description: 'group-[.toast]:text-muted-foreground',
          actionButton:
            'group-[.toast]:bg-primary group-[.toast]:text-primary-foreground',
          cancelButton:
            'group-[.toast]:bg-muted group-[.toast]:text-muted-foreground',
        },
      }}
      {...props}
    />
  );
};

const innerToast = {
  success: (...args: Parameters<typeof toast.success>) => {
    void import('sonner').then(({ toast }) => {
      toast.success(...args);
    });
  },
  error: (...args: Parameters<typeof toast.error>) => {
    void import('sonner').then(({ toast }) => {
      toast.error(...args);
    });
  },
  info: (...args: Parameters<typeof toast.info>) => {
    void import('sonner').then(({ toast }) => {
      toast.info(...args);
    });
  },
  warning: (...args: Parameters<typeof toast.warning>) => {
    void import('sonner').then(({ toast }) => {
      toast.warning(...args);
    });
  },
  custom: (...args: Parameters<typeof toast.custom>) => {
    void import('sonner').then(({ toast }) => {
      toast.custom(...args);
    });
  },
  loading: (...args: Parameters<typeof toast.loading>) => {
    void import('sonner').then(({ toast }) => {
      toast.loading(...args);
    });
  },
  message: (...args: Parameters<typeof toast.message>) => {
    void import('sonner').then(({ toast }) => {
      toast.message(...args);
    });
  },
};

export { Toaster, innerToast as toast };
