export const ERROR_CODES = {
  UNAUTHORIZED: 'UNAUTHORIZED',
  FORBIDDEN: 'FORBIDDEN',
  NOT_FOUND: 'NOT_FOUND',
  INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
  BAD_REQUEST: 'BAD_REQUEST',
  SERVICE_UNAVAILABLE: 'SERVICE_UNAVAILABLE',
  UNKNOWN: 'UNKNOWN',
  PRIZE_DISTRIBUTION_DISCLAIMER_NOT_ACCEPTED:
    'PRIZE_DISTRIBUTION_DISCLAIMER_NOT_ACCEPTED',
  PHONE_NUMBER_NOT_LINKED: 'PHONE_NUMBER_NOT_LINKED',
} as const;

export class PullboxError extends Error {
  code?: (typeof ERROR_CODES)[keyof typeof ERROR_CODES];
  message: string;

  constructor(
    message: string,
    code?: (typeof ERROR_CODES)[keyof typeof ERROR_CODES],
  ) {
    super(message, { cause: code });
    this.code = code;
    this.message = message;
  }
}

export class AuthRequiredError extends PullboxError {
  constructor(message?: string) {
    super(message ?? 'You must be logged in!', ERROR_CODES.UNAUTHORIZED);
  }
}

export class UnknownExceptionError extends PullboxError {
  constructor(message?: string) {
    super(
      message ?? 'An unknown error occurred',
      ERROR_CODES.INTERNAL_SERVER_ERROR,
    );
  }
}

export class PrizeDistributionDisclaimerNotAcceptedError extends PullboxError {
  constructor(message?: string) {
    super(
      message ?? 'You must accept the disclaimer to create a battle',
      ERROR_CODES.PRIZE_DISTRIBUTION_DISCLAIMER_NOT_ACCEPTED,
    );
  }
}

export class PhoneNumberNotLinkedError extends PullboxError {
  constructor(message?: string) {
    super(
      message ?? 'You must link your phone number to create a giveaway.',
      ERROR_CODES.PHONE_NUMBER_NOT_LINKED,
    );
  }
}
