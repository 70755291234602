import BoxCard from './box-card';
import Link from 'next/link';

export default function BoxGrid({
  boxes,
  toggleFavorite,
}: {
  boxes: {
    id: string;
    pathname: string;
    image: string;
    splash: string;
    price: string;
    name: string;
    isFavorite: boolean;
    color: string;
    cov: number;
  }[];
  toggleFavorite: (formData: FormData) => void;
}) {
  return (
    <div
      className="grid w-full max-w-full grid-cols-2 gap-x-4 gap-y-4 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-7"
      data-testid="box-grid"
    >
      {boxes.map((box, index) => (
        <Link href={box.pathname} key={box.id}>
          <BoxCard
            {...box}
            priority={index < 4}
            isFavorite={box.isFavorite}
            toggleFavorite={toggleFavorite}
            color={box.color}
            cov={box.cov}
          />
        </Link>
      ))}
    </div>
  );
}
