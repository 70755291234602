import * as React from 'react';

import { cn } from 'utils/shadcn';

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  iconLeft?: React.ReactNode;
  iconRight?: React.ReactNode;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, iconLeft, iconRight, ...props }, ref) => {
    return (
      <div className="relative flex items-center">
        {iconLeft && (
          <div className="absolute left-3 top-1/2 -translate-y-1/2">
            {iconLeft}
          </div>
        )}
        <input
          type={type}
          className={cn(
            'flex h-9 w-full rounded-md border border-input bg-transparent px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50',
            iconLeft && 'pl-10',
            iconRight && 'pr-10',
            className,
          )}
          ref={ref}
          {...props}
        />
        {iconRight && (
          <div className="absolute right-3 top-1/2 -translate-y-1/2">
            {iconRight}
          </div>
        )}
      </div>
    );
  },
);
Input.displayName = 'Input';

export { Input };
